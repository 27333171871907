'use client'

import useIsMobile from '@/src/hooks/useIsMobile'
import { PAGE_SIZE, defaultInfiniteScrollOptions, postFetcher } from '@/components/utils/pagination'
import Head from 'next/head'
import { useMemo } from 'react'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import HomeDesktop from '../components/HomeDesktop'
import HomeMobile from '../components/HomeMobile'
import { setMetaTags } from '../components/utils/manage-tags'
//todo use getServerSideProps interface instead of any

export async function getServerSideProps({ req, res }: any) {
    res.setHeader('Cache-Control', 'no-store')
    return {
        props: {
            host: req.headers.host
        },
    }
}

interface HomeProps {
    host: string | undefined
}

export default function Home({ host }: HomeProps) {

    const { data: topStoriesPosts } = useSWR(`/api/topStories`, postFetcher)

    const idsFilter = topStoriesPosts?.map((post: any) => post.sys.id)

    const { data: postData, size, setSize, isLoading } = useSWRInfinite((pageIndex, previousPageData) => {
        if (previousPageData && !previousPageData.length) return null
        return `/api/posts?index=${pageIndex}&limit=${PAGE_SIZE}&idsFilter=${idsFilter}`
    }, postFetcher, defaultInfiniteScrollOptions)

    let paginatedPosts = useMemo(() => postData?.flat(), [postData]) || []
    const isEnd = !!postData && postData[postData.length - 1]?.length < PAGE_SIZE

    const isMobile = useIsMobile()

    if (!postData || !topStoriesPosts) return (
        <>Loading...</>
    )
    

    return (
        <>
            <Head>
                {setMetaTags(host, {}, '/')}
            </Head>
            <main id='main' className='bg-body-hot lg:pt-6'>
                <div className='w-full'>
                    {
                        isMobile
                            ? <HomeMobile posts={topStoriesPosts.concat(paginatedPosts)} setSize={setSize} size={size} isEnd={isEnd} />
                            : <HomeDesktop isLoading={isLoading} setSize={setSize} size={size} isEnd={isEnd} topStoriesPosts={topStoriesPosts} otherPosts={paginatedPosts} />
                    }
                </div>
            </main>
        </>
    )
}
